import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('rcc-text-input',_vm._b({ref:"inputRef",attrs:{"mask":"##.##.####","label":_vm.label,"width":_vm.width,"is-disabled":_vm.isDisabled,"rules":_vm.allRules},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"slide-y-transition","attach":_vm.attach,"content-class":_vm.menuClass},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"width":"24","height":"24","icon":"","disabled":_vm.isDisabled}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-calendar")])],1)]}}]),model:{value:(_vm.isShowMenu),callback:function ($$v) {_vm.isShowMenu=$$v},expression:"isShowMenu"}},[_c(VDatePicker,{attrs:{"locale":"ru"},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)]},proxy:true}]),model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'rcc-text-input',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }