<template>
  <rcc-text-input
    ref="inputRef"
    v-model="inputValue"
    :label="label"
    :width="width"
    :height="height"
    :is-disabled="isDisabled"
    type="number"
    :min="min"
    :max="max"
    :rules="allRules"
    v-bind="$attrs"
  >
    <template v-if="isShowButtons" v-slot:append>
      <div class="rcc-number-input__buttons-wrapper">
        <button
          type="button"
          class="rcc-number-input__button"
          :disabled="isDisabled"
          @click="incrementValue"
        >
          <v-icon large>mdi-menu-up</v-icon>
        </button>
        <button
          type="button"
          class="rcc-number-input__button"
          :disabled="isDisabled"
          @click="decrementValue"
        >
          <v-icon large>mdi-menu-down</v-icon>
        </button>
      </div>
    </template>
  </rcc-text-input>
</template>

<script>
import RccTextInput from './rcc-text-input'

export default {
  name: 'RccNumberInput',

  inheritAttrs: false,

  components: { RccTextInput },

  props: {
    label: {
      type: String,
      default: ''
    },

    value: {
      type: Number,
      default: null
    },

    width: {
      type: String,
      default: null
    },

    height: {
      type: String,
      default: null
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    max: {
      type: Number,
      default: null
    },

    min: {
      type: Number,
      default: null
    },

    isShowButtons: {
      type: Boolean,
      default: true
    },

    rules: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    allRules() {
      return [this.valueValidation, ...this.rules]
    },

    inputValue: {
      get() {
        return '' + (this.value !== null ? this.value : '')
      },

      set(value) {
        if (value === '') {
          this.$emit('input', null)
          return
        }

        this.$emit('input', parseInt(value, 10))
      }
    },

    errorText() {
      if (this.min !== null && this.max !== null) {
        return `Значение должно быть в диапазоне от ${this.min} до ${this.max}`
      } else if (this.max === null) {
        return `Значение должно быть не менее ${this.min}`
      }

      return `Значение должно быть не более ${this.max}`
    }
  },

  methods: {
    valueValidation(inputValue) {
      const value = parseInt(inputValue, 10)
      return (this.max === null || value <= this.max) && (this.min === null || value >= this.min) || this.errorText
    },

    incrementValue() {
      this.inputValue = (this.value !== null && this.value + 1) || 0
    },

    decrementValue() {
      this.inputValue = (this.value !== null && this.value - 1) || 0
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-number-input {
  &__buttons-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: -1px;
    right: 5px;
  }

  &__button {
    overflow: hidden;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    padding: 0;
    margin: 0;

    &:active, &:focus, &:hover {
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
</style>
