export function getDetailsList(params) {
  return this.$api.get('status_detail/list', params)
    .then(({ data }) => {
      return data
    })
    .catch(error => Promise.reject(error))
}

export default {
  methods: {
    getDetails(params) {
      return this.$api.get('status_details', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getDetailing(id) {
      return this.$api.get('status_detail/' + id)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    createDetailing(params) {
      return this.$api.post('status_detail/0', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    updateDetailing(id, params) {
      return this.$api.post('status_detail/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}