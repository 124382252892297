<template>
  <v-textarea
    ref="textarea"
    v-model="inputValue"
    class="rcc-textarea"
    :label="label"
    :rules="rules"
    :disabled="isDisabled"
    no-resize
    outlined
    :placeholder="placeholder"
  >
  <template v-slot:append>
    <slot name="append" />
  </template>
  </v-textarea>
</template>

<script>
export default {
  name: 'RccTextarea',

  props: {
    value: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    width: {
      type: String,
      default: null
    },

    rules: {
      type: Array,
      default: () => []
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: ''
    }
  },

  computed: {
    inputValue: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      }
    }
  },

  mounted() {
    if (this.width) {
      this.$refs.textarea.$el.style.width = this.width
    }
  }
}
</script>

<style lang="scss">
.rcc-textarea {
  .v-input__slot {
    background: $text-input-bg !important;

    fieldset {
      border-color: $text-input-border-color;
    }

    &:hover {
      fieldset {
        border-color: $text-input-hovered-border-color;
      }
    }
  }
}
</style>
