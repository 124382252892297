export function objectToOptions({ object, keyName = 'id', valueName = 'value', keys = [] }) {
  const options = []

  for (var key in object) {
    options.push({ [keyName]: key, [valueName]: object[key] })
  }

  return options
}

export function arrayToOptions({ array, keyName = 'id', valueName = 'value', key = 'id', value = 'name' }) {
  const options = []

  try {
    array.forEach(item => {
      options.push({ ...item, [keyName]: item[key], [valueName]: item[value] })
    })
  } catch (err) {
    console.dir(err)
    return []
  }

  return options
}

export function getOptions(options) {
  options.forEach(({ method, target, errorMessage, keyName = 'id', valueName = 'value', value = 'name' }) => {
    method.bind(this)()
      .then(({ items }) => {
        this[target] = arrayToOptions({ array: items, value, keyName, valueName })
      })
      .catch(() => { window.snackbar(errorMessage, 'error') })
  })
}

export function arrayToObject({ array, key = id, value = value }) {
  const object = {}

  array.forEach(item => {
    object[item[key]] = item[value]
  })

  return object
}
